import api from './api';
import qs from 'qs';

async function createApplication(applicationName, applicationUrl) {
  const res = await api.post(
    '/oauth2/apps/',
    qs.stringify({
      application_name: applicationName,
      application_url: applicationUrl,
    }),
  );
  return res.data;
}

async function getApplicationById(id) {
  const res = await api.get(`/oauth2/apps/${id}`);
  return res.data;
}

async function getApplications() {
  const res = await api.get(`/oauth2/apps`);
  return res.data;
}

async function updateApplication(
  application_id,
  application_name,
  application_url,
  app_icon,
  app_favicon,
  theme,
  policy_url,
  terms_of_service_url,
  is_consent_display,
  auth_providers,
  version,
  background_color,
) {
  const res = await api.put(
    `/oauth2/apps/${application_id}`,
    qs.stringify({
      application_name,
      application_url,
      app_icon,
      app_favicon,
      theme,
      policy_url,
      terms_of_service_url,
      is_consent_display,
      auth_providers: JSON.stringify(auth_providers),
      version,
      background_color,
    }),
  );
  return res.data;
}

async function createCANAccount(appId) {
  const res = await api.post(`/oauth2/apps/${appId}/can_account`);
  return res;
}

export {
  getApplicationById,
  getApplications,
  createApplication,
  updateApplication,
  createCANAccount,
};
