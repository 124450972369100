import React from 'react';
import styled from 'styled-components/macro';
import { colors } from '../../Theme/constants';
import PropTypes from 'prop-types';
import iconClose from 'Assets/Images/ic-close.svg';
import { Button } from '../../Components/Button';
import { Link } from 'react-router-dom';
import { ButtonLink } from '../../Components/ButtonLink';
import { useSelect } from '../../Hooks';
import { withRouter } from 'react-router';

const ApplicationsModalStyled = styled.div`
  background-color: ${colors.white};
  position: relative;
  width: 36rem;
  margin: 0 auto;
  max-height: 50rem;
  overflow: auto;
`;
const Head = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.6rem;
  padding: 2rem 3.2rem;
`;
const CloseButton = styled.button`
  padding: 0;
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 1.2rem;
  top: 1.2rem;
  width: 1.2rem;
  background: transparent;
`;
const Body = styled.div`
  margin-bottom: 1.6rem;
  h6 {
    text-align: center;
    font-weight: normal;
  }
  ul {
    li {
      text-align: center;
      a {
        color: ${colors.black};
        padding: 1.6rem 0;
        font-size: 1.4rem;
        display: block;
        &:hover {
          background-color: ${colors.grey200};
        }
      }
      &.isActive {
        a {
          background-color: ${colors.grey200};
          color: ${colors.blue};
        }
      }
    }
  }
`;
const Foot = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 2rem 1.2rem;
`;

function ApplicationsModal(props) {
  const { handleClose } = props;
  const { applicationList, currentApplication } = useSelect(
    ({ application }) => ({
      applicationList: application.applicationsSelector,
      currentApplication: application.currentApplicationSelector,
    }),
  );

  const existApplicationList = applicationList && applicationList.length > 0;
  return (
    <ApplicationsModalStyled>
      <CloseButton onClick={handleClose}>
        <img src={iconClose} alt="icon" />
      </CloseButton>
      <Head>
        <h5>Applications</h5>
      </Head>
      <Body>
        <ul>
          {existApplicationList ? (
            applicationList.map(item => (
              <li
                key={item.application_id}
                className={
                  currentApplication &&
                  currentApplication.application_id === item.application_id
                    ? 'isActive'
                    : ''
                }
              >
                <Link
                  to={`/oauth2/app?appId=${item.application_id}`}
                  onClick={handleClose}
                >
                  {item.application_name}
                </Link>
              </li>
            ))
          ) : (
            <h6>No Application</h6>
          )}
        </ul>
      </Body>

      <Foot>
        <Button
          onClick={handleClose}
          borderRadius={0.5}
          height={3.2}
          fontSize={1.4}
          backgroundColor="transparent"
          color={colors.grey}
        >
          Cancel
        </Button>
        <ButtonLink
          onClick={handleClose}
          borderRadius={0.5}
          height={3.2}
          fontSize={1.4}
          backgroundColor={colors.yellow}
          color={colors.blue900}
          padding="0 1.2rem"
        >
          <Link to="/oauth2/app/create">Create new App</Link>
        </ButtonLink>
      </Foot>
    </ApplicationsModalStyled>
  );
}

ApplicationsModal.propTypes = {
  handleClose: PropTypes.func,
};

export default withRouter(ApplicationsModal);
